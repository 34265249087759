const fixFactor = Math.sqrt(2);
export class SquareDrawer {
    draw(context, particle, radius) {
        const fixedRadius = radius / fixFactor, fixedDiameter = fixedRadius * 2;
        context.rect(-fixedRadius, -fixedRadius, fixedDiameter, fixedDiameter);
    }
    getSidesCount() {
        return 4;
    }
}
